import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { isOdd } from "../../utils/isOdd"
import { InformationItem } from "../information-item"

export const CourseList = ({ showPhotography }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCourse {
        nodes {
          contentful_id
          isPhotography
          title
          description {
            description
          }
          terms {
            raw
          }
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
    }
  `)

  const nodes = data.allContentfulCourse.nodes

  const photographyCourses = nodes.reduce((acc, current) => {
    current.isPhotography && acc.push(current)

    return acc
  }, [])

  const videographyCourses = nodes.reduce((acc, current) => {
    !current.isPhotography && acc.push(current)

    return acc
  }, [])

  return (
    <section>
      {showPhotography
        ? photographyCourses.map((course, index) => (
            <InformationItem key={index} data={course} isOdd={isOdd(index)} />
          ))
        : videographyCourses.map((course, index) => (
            <InformationItem key={index} data={course} isOdd={isOdd(index)} />
          ))}
    </section>
  )
}
