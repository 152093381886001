import React, { useState, useCallback } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { css, useTheme } from "@emotion/react"
import { mediaQuery } from "../utils/mediaQuery"
import { CourseFilter } from "../components/courses/course-filter"
import { CourseList } from "../components/courses/course-list"
import { CourseInfo } from "../components/courses/course-info"

const linkStyles = css`
  text-decoration: none;
  color: inherit;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const Courses = () => {
  const theme = useTheme()
  const [showPhotography, setShowPhotography] = useState(true)

  const callback = useCallback(type => {
    if (type === "photography") {
      return setShowPhotography(true)
    }
    setShowPhotography(false)
  }, [])

  return (
    <Layout>
      <Seo title="Courses" />
      <div
        css={css`
          color: white;
          background-color: black;

          ${mediaQuery[1]} {
            margin-top: ${theme.heights.header};
          }
        `}
      >
        <CourseFilter callback={callback} showPhotography={showPhotography} />
        <CourseInfo />
        <CourseList showPhotography={showPhotography} />
        <section
          css={css`
            margin: 20px 5%;
            padding-bottom: 20px;
          `}
        >
          <h4>Please Note:</h4>
          <p>
            These prices include the use of our cameras, however do not include
            dive equipment, we can recommend a dive centre nearby where you can
            rent high quality gear, just let us know!
            <br />
            <br />
            Please note: PADI Open Water Diver (or equivalent) is required to
            take any of our courses, but if you’re looking to get into the world
            of diving we can recommend you a dive center! If you prefer to
            freedive and already have some experience in it, also feel free to
            get in touch and a course can be designed specifically for you!
            <br />
            <br />
            For further information, please contact us:
            <br />
            <br />
          </p>
          <ul>
            <li>
              <a css={linkStyles} href={`mailto:harry@tujofilms.com`}>
                harry@tujofilms.com
              </a>
            </li>
          </ul>
        </section>
      </div>
    </Layout>
  )
}

export default Courses
