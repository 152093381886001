import React from "react"
import { css } from "@emotion/react"
import { mediaQuery } from "../../utils/mediaQuery"

const buttonStyles = css`
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
`

const textStyles = css`
  font-size: 1rem;
  margin: 0;
  color: white;

  ${mediaQuery[1]} {
    font-size: 1.5rem;
  }

  :hover {
    text-decoration: underline;
  }
`

const photographyStyle = showPhotography => css`
  ${showPhotography && ` text-decoration: underline;`}
`

// const videographyStyle = showPhotography => css`
//   ${!showPhotography && `text-decoration: underline;`}
// `

export const CourseFilter = ({ callback, showPhotography }) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-evenly;
        margin: 0 auto;
        padding: 25px;
      `}
    >
      <button onClick={() => callback("photography")} css={buttonStyles}>
        <h1 css={[textStyles, photographyStyle(showPhotography)]}>
          PHOTOGRAPHY
        </h1>
      </button>
      {/* <button onClick={() => callback("videography")} css={buttonStyles}>
        <h1 css={[textStyles, videographyStyle(showPhotography)]}>
          VIDEOGRAPHY
        </h1>
      </button> */}
    </div>
  )
}
