import React from "react"
import { css } from "@emotion/react"

export const CourseInfo = () => (
  <p
    css={css`
      margin: 0 5% 20px;
    `}
  >
    Welcome to Tujo Films! With years of experience in shooting underwater, our
    enthusiastic team is keen to share its knowledge through a variety of one
    and two day courses. Tujo films are based in multiple locations throughout
    Europe including the UK, Switzerland and Ireland. If you’re nearby where we
    know the perfect sites to help you take your first underwater photos and
    videos, or to help you refine your existing skills.
    <br /> <br />
    If you’ve got a spot in mind we are well equipped to travel! Sometimes we’ll
    need to charge a fee to help us get there, although this isn’t always the
    case, even we need a dive trip every now and then!
    <br /> <br /> Feel free to check out the courses on offer below and get in
    touch
  </p>
)
